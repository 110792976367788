import React, { createContext, FC } from "react";
import { HistoryModalContext, HistoryModalContextProvider } from "./HistoryModalContext";
import { Web3ProviderContext, Web3ProviderContextProvider } from "./Web3ProviderContext";

const contexts = {
    historyModal: HistoryModalContext,
    web3Provider: Web3ProviderContext,
};

const RootContext = createContext(contexts);

const RootContextProvider: FC = ({ children }) => {
    return (
        <RootContext.Provider value={contexts}>
            <HistoryModalContextProvider>
                <Web3ProviderContextProvider>{children}</Web3ProviderContextProvider>
            </HistoryModalContextProvider>
        </RootContext.Provider>
    );
};

export { RootContext, RootContextProvider };
