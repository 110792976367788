import React from "react";
import cn from "classnames";

import "./DataContainer.scss";

interface DataContainerProps {
    className?: string;
    padding?: number;
    children: React.ReactNode;
    onClick?: () => void;
}

const DataContainer = ({ children, className, padding = 32, onClick }: DataContainerProps) => (
    <div className={cn("data-container", className)} style={{ padding }} onClick={onClick}>
        {children}
    </div>
);

export default DataContainer;
