import React from "react";
import { getShortAddress } from "../../../../utils/network";
import "./HeaderAddress.scss";

interface HeaderAddressProps {
    onClick?: () => void;
    currentAddress: string;
}

const HeaderAddress = ({ currentAddress, onClick }: HeaderAddressProps) => {
    return (
        <div onClick={onClick} className="header-address">
            {getShortAddress(currentAddress)}
        </div>
    );
};

export default HeaderAddress;
