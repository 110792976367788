import { ethers } from "ethers";
import address from "../constants/address";
import MULTICALL_ABI from "../constants/multicall_abi.json";

import { NETWORK, networkInfo, networkToId } from "../utils/network";

const useMulticall = () => {
    const provider = new ethers.providers.JsonRpcProvider(
        networkInfo[NETWORK.stepnet].rpcUrls[0],
        +networkToId[NETWORK.stepnet]
    );
    return new ethers.Contract(address.MULTICALL, MULTICALL_ABI, provider);
};

export default useMulticall;
