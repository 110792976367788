import React, { Context, useContext } from "react";
import { RootContext } from "./RootContext";

const useRootContext = () => {
    return useContext(RootContext);
};

type RootState = ReturnType<typeof useRootContext>;

function useContextSelector<Selected>(selector: (state: RootState) => Context<Selected>) {
    const root = useContext(RootContext);

    return useContext<Selected>(selector(root));
}

export default useContextSelector;
