import React, { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import Web3Modal, { IProviderOptions } from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { changeNetworkAtMetamask, NETWORK, networkInfo, networkToId } from "../../utils/network";

import Header from "../Header/Header";
import HomePage from "../HomePage/HomePage";

import "./App.scss";
import { RootContext } from "../../contexts/RootContext";
import useContextSelector from "../../contexts/contextSelector";
import RoadmapComponent from "../RoadmapComponent/RoadmapComponent";

const App = () => {
    // const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);

    // const { setIsHistoryModalVisible, isHistoryModalVisible } = useContextSelector((state) => state.historyModal);

    // const closeHistoryModal = () => {
    //     setIsHistoryModalVisible(false);
    // };

    return (
        <div className="App">
            <HomePage
            // web3={web3}
            // currentAddress={currentAddress}
            // onConnect={onConnect}
            // closeHistoryModal={closeHistoryModal}
            // isHistoryModalVisible={isHistoryModalVisible}
            />
            {/* <RoadmapComponent /> */}
        </div>
    );
};

export default App;
