const countdownTimer = (date: number): string | null => {
    const publicSaleDate = new Date(date);
    const dateNow = new Date();

    const remainingTime = publicSaleDate.valueOf() - dateNow.valueOf();

    const remainingSeconds = Math.floor((remainingTime / 1000) % 60);
    const remainingMinutes = Math.floor((remainingTime / (1000 * 60)) % 60);
    const remainingHours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
    const remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));

    const time = {
        days: remainingDays < 10 ? `0${remainingDays}` : remainingDays,
        hours: remainingHours < 10 ? `0${remainingHours}` : remainingHours,
        minutes: remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes,
        seconds: remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds,
    };

    if (remainingHours + remainingMinutes + remainingSeconds + remainingDays <= 0) {
        return null;
    }

    return `${time.days}:${time.hours}:${time.minutes}:${time.seconds}`;
};

export default countdownTimer;
