import React from "react";

interface Props {
    children: any;
}

class ErrorBoundary extends React.Component<Props> {
    componentDidCatch(error: Error) {
        console.error(error.message);
    }

    render() {
        const { children } = this.props;

        return children;
    }
}

export default ErrorBoundary;
