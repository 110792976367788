import React, { createContext, FC } from "react";
import Web3 from "web3";
import useWeb3Provider from "../hooks/useWeb3Provider";

interface IContext {
    web3Provider: Web3;
    currentAddress: string | undefined;
    isCorrectChainId: boolean | undefined;
    onConnect: () => void;
    onDisconnect: () => void;
}

const Web3ProviderContext = createContext<IContext>({
    web3Provider: new Web3(),
    currentAddress: "",
    isCorrectChainId: false,
    onConnect: () => {},
    onDisconnect: () => {},
});
const Web3ProviderContextProvider: FC = ({ children }) => {
    const { provider: web3Provider, currentAddress, isCorrectChainId, onConnect, onDisconnect } = useWeb3Provider();

    return (
        <Web3ProviderContext.Provider
            value={{ web3Provider, currentAddress, isCorrectChainId, onConnect, onDisconnect }}
        >
            {children}
        </Web3ProviderContext.Provider>
    );
};

export { Web3ProviderContext, Web3ProviderContextProvider };
