import React, { createContext, FC, useState } from "react";

const HistoryModalContext = createContext({
    isHistoryModalVisible: false,
    setIsHistoryModalVisible: (state: boolean): void => {},
});

const HistoryModalContextProvider: FC = (props) => {
    const { children } = props;

    const [visible, setVisible] = useState(false);

    const visibleHandler = (state: boolean) => {
        setVisible(state);
    };
    return (
        <HistoryModalContext.Provider
            value={{ isHistoryModalVisible: visible, setIsHistoryModalVisible: visibleHandler }}
        >
            {children}
        </HistoryModalContext.Provider>
    );
};

export { HistoryModalContext, HistoryModalContextProvider };
