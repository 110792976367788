import React, { FC } from "react";

export const ArrowIcon: FC<React.HTMLAttributes<SVGElement>> = (props) => {
    return (
        <svg width="48" height="22" viewBox="0 0 48 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M36.0234 21L45.625 11L36.0234 1M44.5234 11H0.0234375H44.5234Z"
                stroke="white"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};
