import React from "react";
import cn from "classnames";

import "./Button.scss";

interface ButtonProps {
    className?: string;
    children: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
}
const Button = ({ children, className, onClick, disabled }: ButtonProps) => (
    <div className={cn("button", className, { "button--disabled": disabled })} onClick={onClick}>
        {children}
    </div>
);

export default Button;
